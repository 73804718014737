import { Container } from '@mui/material';
import { Box } from '@mui/system';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import HeroBanner from '../components/HeroBanner';
import Layout from '../components/Layout';
import FormEmbed from '../components/shared/FormEmbed';
import { withHelmet } from '../helpers/metadataHelpers';

export const VolunteerPageTemplate = ({
  title,
  embed,
}) => (
  <section>
    <HeroBanner title={title}/>
    <Container disableGutters>
      <Box py={4}>
        <FormEmbed embed={embed} embedStyles={{ width: '100%', height: { xs: '160rem', sm: '155rem', md: '150rem' }}} />
      </Box>
    </Container>
  </section>
)


const VolunteerPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <Box sx={{ textAlign: 'center' }}>
        <VolunteerPageTemplate
          {...post.frontmatter}
        />
      </Box>
    </Layout>
  )
}

VolunteerPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default withHelmet(VolunteerPage)

export const volunteerPageQuery = graphql`
  query VolunteerPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "volunteer-page" } }) {
      frontmatter {
        title
        pagename
        description
        embed
      }
    }
  }
`
