import React from 'react'
import { Box } from '@mui/material';

const FormEmbed = ({ embed, embedStyles }) => (
  <Box sx={{ '& iframe': embedStyles }}>
    <div
      dangerouslySetInnerHTML={ {__html: embed}}
    />
  </Box>
)

export default FormEmbed
